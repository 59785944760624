<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" ref="divScroll" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader" @change="parkClear"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.region')">
            <el-select
              v-model.trim="formInline.areaId"
              @change="areaChange"
              filterable
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select
              v-model.trim="formInline.streetId"
              @change="streetChange"
              filterable
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList2"
              ></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.park_name')">
                <a-park-select
                  ref="parkSelect"
                  :instance="this"
                  parkTypeRefName="parkTypeSelect"
                  operationRefName="operationSelect"
                  areaRefName="cascader"
                ></a-park-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Date_search')">
                <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Date_search')">
            <el-select @change="typeChange" v-model.trim="formInline.dateType" filterable>
              <el-option label="日" value="1"></el-option>
              <el-option label="月" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              :value-format="formInline.dateType == 1 ? 'yyyy-MM-dd' : 'yyyy-MM'"
              v-model="formInline.dateArray"
              :type="formInline.dateType == 1 ? 'daterange' : 'monthrange'"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item> -->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData()"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()" :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="marginTB20">
        <picEts :totalstatics="totalstatics"></picEts>
      </div>
      <div class="marginTB20">
        <el-row :gutter="20">
          <el-col :span="16">
            <div class="grid-content bg-purple graphShadow paddingLR20 paddingT10">
              <graphTitle :title="'新能源趋势分析'">
                <el-tooltip
                  placement="top"
                  content="展示统计时间段内的新能源停车和非新能源停车数量的变化趋势"
                >
                  <i class="el-icon-question my-icon" />
                </el-tooltip>
              </graphTitle>
              <div id="newCarLine" class="pieEchats"></div>
              <!-- <line-chart :lineChartLabel="lineChartLabel" :lineChartValue="lineChartValue"/> -->
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple graphShadow paddingLR20 paddingT10">
              <div class="flexBetween">
                <graphTitle :title="'新能源车排名'"> </graphTitle>
                <el-button
                  size="mini"
                  @click="exportFile"
                  type="info"
                  icon="el-icon-upload2"
                  v-if="$route.meta.authority.button.export"
                  >{{ $t('button.export') }}</el-button
                >
              </div>
              <!-- <newRanking ref="rankingincome" :areaType.sync="rankData" :searchData="searchFind"></newRanking> -->
              <rankingList
                :activeNameTwo="activeName"
                :rankingNumber="rankingNumber"
                :parking="runking"
                @getranking="getranking"
              ></rankingList>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import rankingList from "./rankingList";
import picEts from "./picEcharts";
import myComponent from "@/components/autocomplete/myautoComponent";
import graphTitle from "@/components/graphTitle";

import { exportExcelNew, checkDate } from "@/common/js/public.js";
export default {
  name: "parkIncomeDayStatics",
  data() {
    let startDate = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
    let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
    return {
      selectkeys: ["date", "month", "week", "year"],
      desc: 0,
      areaType: 1,
      activeName: "first",
      rankingNumber: 0,
      runking: [],
      areaList2: [],
      rankData: {},
      rankData1: {},
      areaId: "",
      tenantId: "",
      areaList: [],
      // 折线图样式
      type2: "c2",
      parkList: [],
      arrearageStrip: [],
      modelvalue: "",
      tableData: [],
      searchFind: null,
      searchFind1: null,
      pName: "",
      index: 0,
      page: 1,
      startDate: "",
      endDate: "",
      pageSize: 15,
      total: 0,
      loading: false,
      // 进出场车型汇总统计
      totalstatics: {},
      detailFlag: false,
      formInline: {
        parkId: "",
        areaId: "",
        streetId: "",
        dateType: "1",
        dateArray: [startDate, endDate],
      },
      dateArray: [],
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 切换排行榜
    getranking(desc, num) {
      this.desc = desc;
      this.areaType = num;
      let { dateArray, ...data } = this.formInline;
      data.parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
      data.streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
      data.areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
      data.operationId = this.$refs.operationSelect
        ? this.$refs.operationSelect.getOperationId()
        : "";
      data.dataSource = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
      data.dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      data.startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      data.endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      this.$axios
        .get("/acb/2.0/carStatistics/newEnergyRank", {
          data: {
            desc: desc,
            ...data,
            areaType: num,
          },
        })
        .then((res) => {
          this.rankingNumber = 2;
          this.runking = res.value;
        });
    },
    // 改变城市
    areaChange(item) {
      if (!item) {
        this.formInline.streetId = "";
        return;
      }
      this.resetCarPark();
      this.areaList2 = [];
      this.getChildrenArea(item);
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    // 改变区域
    streetChange(item) {
      this.resetCarPark();
    },
    // 清空车场组件信息
    resetCarPark() {
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      // this.$refs.parkInput.setShowVal("");
    },
    // 车场选择
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 日期
    typeChange(v) {
      if (v == 1) {
        let startDate = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
        let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
        this.formInline.dateArray = [startDate, endDate];
      } else if (v == 2) {
        let startDate = this.$moment().subtract(11, "months").format("YYYY-MM");
        let endDate = this.$moment().format("YYYY-MM");
        this.formInline.dateArray = [startDate, endDate];
      }
    },
    // 清空
    resetForm() {
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.formInline.dateType = "1";
      this.resetCarPark();
      this.typeChange(1);
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      this.$refs.parkSelect.clear();
    },
    // 导出
    exportFile() {
      let { dateArray, ...data } = this.formInline;
      data.parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
      data.streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
      data.areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
      data.dataSource = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
      data.operationId = this.$refs.operationSelect
        ? this.$refs.operationSelect.getOperationId()
        : "";
      data.dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      data.startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      data.endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      let opt = {
        desc: this.desc,
        ...data,
        areaType: this.areaType,
      };
      let url = "/acb/2.0/carStatistics/exportNewEnergyRank";
      exportExcelNew(url, opt);
    },
    carTypeLine(date, bigCnt, midCnt, microCnt) {
      let echarts = this.$echarts.init(document.getElementById("carTypeLine"));
      let option = {
        title: {
          text: "车型分析",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["小型车", "中型车", "大型车"],
          right: "5%",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
          show: false,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: date || [],
        },
        yAxis: {
          name: "车辆数（辆）",
          type: "value",
          splitLine: {
            // 网格线
            show: false,
          },
        },
        series: [
          {
            name: "小型车",
            type: "line",
            data: microCnt || [],
          },
          {
            name: "中型车",
            type: "line",
            data: midCnt || [],
          },
          {
            name: "大型车",
            type: "line",
            data: bigCnt || [],
          },
        ],
      };
      echarts.setOption(option, true);
    },
    newCarLine(date, total, energyArr, fuelArr) {
      let echarts = this.$echarts.init(document.getElementById("newCarLine"));
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{b}<br/>{a0}:{c0}(辆) <br/>{a1}:{c1}(辆)",
        },
        legend: {
          data: ["新能源", "非新能源"],
          right: "5%",
        },
        grid: {
          left: "5%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
          show: false,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: date || [],
        },
        yAxis: {
          name: "车辆数（辆）",
          type: "value",
          splitLine: {
            // 网格线
            show: false,
          },
        },
        series: [
          {
            name: "新能源",
            type: "line",
            data: energyArr || [],
          },
          {
            name: "非新能源",
            type: "line",
            data: fuelArr || [],
          },
        ],
      };
      echarts.setOption(option, true);
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    searchData() {
      this.loading = true;
      //  let {dateArray, ...data} = this.formInline;
      // if (!this.formInline.dateArray) {
      //   this.$alert("日期不能为空", "提示");
      //   return;
      // }
      // if (!checkDate({
      //   start: this.formInline.dateArray[0],
      //   end: this.formInline.dateArray[1],
      //   dateType: this.formInline.dateType,
      //   dayLimit: 30,
      //   monthLimit: 12
      // })) return;
      if (this.activeName === "first") {
        this.activeName = "firstq";
      } else {
        this.activeName = "first";
      }
      // if (this.formInline.dateType == "1" && !this.formInline.dateArray[0]) {
      //   this.$alert("开始日期不能为空！", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine')
      //   });
      //   return;
      // }
      // if (this.formInline.dateType == "1") {
      //   if (!this.formInline.dateArray[1]) {
      //     this.$alert("结束日期不能为空！", this.$t('pop_up.Tips'), {
      //       confirmButtonText: this.$t('pop_up.Determine')
      //     });
      //     return;
      //   }
      // }
      let url = "";
      // if (this.formInline.dateType == '2') {
      //   let diff = new Date(this.formInline.date1) - new Date(this.formInline.date2).getTime();
      //   let days = Math.abs(diff / (24 * 60 * 60 * 1000))
      //   if (diff > 0) {
      //     this.$alert('开始日期不能大于结束日期', this.$t('pop_up.Tips'), {
      //       confirmButtonText: this.$t('pop_up.Determine')
      //     });
      //     return false;
      //   } else if (days > 365) {
      //     this.$alert('只能查询12个月的记录', this.$t('pop_up.Tips'), {
      //       confirmButtonText: this.$t('pop_up.Determine')
      //     });
      //     return false;
      //   } else {
      //     url = '/acb/2.0/parkingAnalysis/duration';
      //   }
      // } else {
      //   let diff = new Date(this.formInline.startDate) - new Date(this.formInline.endDate).getTime();
      //   let days = Math.abs(diff / (24 * 60 * 60 * 1000))
      //   if (diff > 0) {
      //     this.$alert('开始日期不能大于结束日期', this.$t('pop_up.Tips'), {
      //       confirmButtonText: this.$t('pop_up.Determine')
      //     });
      //     return false;
      //   } else if (days >= 31) {
      //     this.$alert('只能查询31天的记录', this.$t('pop_up.Tips'), {
      //       confirmButtonText: this.$t('pop_up.Determine')
      //     });
      //     return false;
      //   } else {
      //     url = '/acb/2.0/parkingAnalysis/duration';
      //   }
      // }
      // 车型分析和新能源
      let { dateArray, ...data } = this.formInline;
      data.parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
      data.streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
      data.areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
      data.dataSource = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
      data.operationId = this.$refs.operationSelect
        ? this.$refs.operationSelect.getOperationId()
        : "";
      data.dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      data.startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      data.endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      this.$axios
        .get("/acb/2.0/carStatistics/carTypeTotalStatics", {
          data: {
            ...data,
          },
        })
        .then((res) => {
          this.totalstatics = res.value;
        })
        .finally(() => {
          this.loading = false;
        });

      // 新能源车趋势图
      this.$axios
        .get("/acb/2.0/carStatistics/newEnergyTrend", {
          data: {
            ...data,
          },
        })
        .then((res) => {
          let date = [];
          let total = [];
          let energyArr = [];
          let fuelArr = [];
          res.value.forEach((ele) => {
            date.push(ele.dataDate);
            total.push(ele.totalCarCnt);
            energyArr.push(ele.newEnergyCarCnt);
            fuelArr.push(ele.fuelCarCnt);
          });
          this.newCarLine(date, total, energyArr, fuelArr);
        })
        .finally(() => {
          this.loading = false;
        });

      // 能源排行榜
      this.$axios
        .get("/acb/2.0/carStatistics/newEnergyRank", {
          data: {
            ...data,
            areaType: 1,
          },
        })
        .then((res) => {
          this.rankingNumber = 2;
          this.runking = res.value;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    receiveData(data) {
      this.formInline.parkId = data.value.parkId;
    },
  },
  beforeDestroy() {},
  components: {
    rankingList,
    picEts,
    graphTitle,
  },
  mounted() {
    this.searchData();
  },
  created() {
    this.getArea();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">

.pieEchats {
  width: 100%;
  height: 400px;
}
</style>
