<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="grid-content bg-purple paddingT10 paddingLR20 graphShadow">
          <graphTitle :title="'新能源车辆占比'">
            <el-tooltip placement="top"
                        content="展示统计期间出现过的能源车辆数与非新能源车辆数之间的对比情况">
              <i class="el-icon-question my-icon" />
            </el-tooltip>
          </graphTitle>
          <div id="newCarecharts"
               class="pieEchats paddingB10"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple paddingT10 paddingLR20 graphShadow">
          <graphTitle :title="'车型分类'">
            <el-tooltip placement="top"
                        content="展示统计时间段内不同车型车之间数量的对比情况">
              <i class="el-icon-question my-icon" />
            </el-tooltip>
          </graphTitle>
          <div id="carTypeEcharts"
               class="pieEchats paddingB10"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    totalstatics: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  watch: {
    totalstatics: {
      handler(value) {
        if (value) {
          this.newCarecharts();
          this.echarts();
        }
      },
      deep: true
    }
  },
  methods: {
    newCarecharts() {
      let echarts = this.$echarts.init(
        document.getElementById("newCarecharts")
      );
      let option = {
        title: {
          text: "",
          x: "left"
        },
        color: ["#2FC25B", "#1890FF"],
        tooltip: {
          trigger: "item",
          formatter: "数量: {c}(辆) <br/> 占比: {d}%"
        },
        legend: {
          orient: "horizontal",
          bottom: 1,
          data: ["新能源车", "非新能源车"]
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center"
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "30",
                  fontWeight: "bold"
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { value: this.totalstatics.newEnergyCarCnt, name: "新能源车" },
              { value: this.totalstatics.fuelCarCnt, name: "非新能源车" }
            ]
          }
        ]
      };
      echarts.setOption(option, true);
    },
    echarts() {
      let echartsId = this.$echarts.init(
        document.getElementById("carTypeEcharts")
      );
      let option = {
        title: {
          text: "",
          x: "left"
        },
        color: ["#1890FF", "#13C2C2", "#2FC25B"],
        tooltip: {
          trigger: "item",
          formatter: "{b}<br/>数量:{c}(辆) <br/>占比:{d}%"
        },
        legend: {
          orient: "horizontal",
          bottom: 1,
          data: ["大型车", "中型车", "小型车"]
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["50%", "70%"],
            center: ["50%", "50%"],
            label: {
              normal: {
                show: false,
                position: "center",
                // formatter: "{b|{b}：} \n {c}  {per|{d}%}\n  ",
                // backgroundColor: "#eff2f7",
                // borderColor: "#aaa",
                // borderRadius: 4,
                // height: 60,
                // rich: {
                //   a: {
                //     color: "#999",
                //     lineHeight: 24
                //     // align: "center"
                //   },
                //   b: {
                //     fontSize: 16,
                //     lineHeight: 33
                //   },
                //   per: {
                //     color: "#eee",
                //     backgroundColor: "#334455",
                //     padding: [4, 4],
                //     borderRadius: 2
                //   }
                // }
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "30",
                  fontWeight: "bold"
                }
              }
            },
            labelLine: { normal: { show: true, length: 2 } },
            data: [
              { value: this.totalstatics.bigCarCnt, name: "大型车" },
              { value: this.totalstatics.miCardCnt, name: "中型车" },
              { value: this.totalstatics.microCarCnt, name: "小型车" }
            ]
          }
        ]
      };
      echartsId.setOption(option, true);
    }
  },
  mounted() {
    this.newCarecharts();
    this.echarts();
  },
  components: {
    graphTitle
  }
};
</script>
<style scoped>
.pieEchats {
  width: 100%;
  height: 430px;
}
</style>
